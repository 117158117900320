import { useMutation } from 'graphql-hooks';
import { useState, useCallback } from 'react';

export const SET_USER_IMPORT_CATEGORIES_MUTATION = `
  mutation SetUserImportCategories($classificationsIds: [String!]!) {
    setUserImportCategories(classificationsIds: $classificationsIds) {
      selectionId
    }
  }
`;

interface SetUserImportCategoriesResponse {
  setUserImportCategories: {
    selectionId: string;
  };
}

export const useSetUserImportCategories = () => {
  const [selectionId, setSelectionId] = useState<string | null>(null);
  const [setUserImportCategories, { loading, error }] = useMutation<SetUserImportCategoriesResponse>(
    SET_USER_IMPORT_CATEGORIES_MUTATION
  );

  const executeSetCategories = useCallback(async (classificationsIds: string[]) => {
    const result = await setUserImportCategories({
      variables: { classificationsIds }
    });
    
    if (result.data) {
      setSelectionId(result.data.setUserImportCategories.selectionId);
    }
    
    return result;
  }, [setUserImportCategories]);

  return {
    setUserImportCategories: executeSetCategories,
    selectionId,
    loading,
    error,
  };
}; 