import { useRef } from 'react';

export const AccordionItem = ({ title, component, isOpen, onClick, index, data }) => {
  const contentHeight = useRef();
  return (
    <div
      className={`border border-b-1 border-gray-200 overflow-hidden ${index === 0 && 'rounded-t-lg'} ${index === data.length - 1 && 'rounded-b-lg'}`}
    >
      <button
        className={`w-full text-left p-2.5 flex items-center justify-between font-medium text-md cursor-pointer bg-gray-50 hover:text-primary-700 hover:bg-primary-100 ${isOpen ? 'text-primary-700 bg-primary-100' : 'text-gray-400'}`}
        onClick={onClick}
      >
        <p className='p-0 m-0'>{title}</p>
      </button>
      <div
        ref={contentHeight}
        className='px-0 py-0 transition-[height] ease-in-out duration-300'
        style={isOpen ? { height: 'calc(100vh - 279px)' } : { height: '0px' }}
      >
        <div className='p-4 h-full'>{component}</div>
      </div>
    </div>
  );
};
