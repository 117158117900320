import { useManualQuery } from 'graphql-hooks';

export const GET_USER_IMPORT_CATEGORIES_QUERY = `
  query GetUserImportCategories {
    getUserImportCategories {
      classificationsIds
    }
  }
`;

export const useGetUserImportCategories = () => {
  const [getUserImportCategories, { loading, error, data }] = useManualQuery(GET_USER_IMPORT_CATEGORIES_QUERY);

  return {
    getUserImportCategories,
    loading,
    error,
    data,
  };
}; 