import React from 'react';
import AttachmentIcon from 'components/utils/iconComponents/AttachtmentIcon';

const UploadFileIcon = ({ orderInfo, handleFileChange }) => {

  return (
    <>
      <div>
        <label htmlFor={`${orderInfo ? 'fileInput' : ''}`}>
          <span
            className={ `${
              orderInfo ? 'cursor-pointer' : 'cursor-not-allowed'
            }`}
          >
            <AttachmentIcon title='Adjuntar archivosss' color={`${orderInfo ? '#475467' : '#d1d5db'}`} />
          </span>
        </label>
      </div>
      <div>
        <input
          className='hidden'
          id='fileInput'
          type='file'
          multiple
          accept='*/*'
          onChange={handleFileChange}
        />
      </div>
    </>
  );
};

export default UploadFileIcon;
