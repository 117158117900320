import { useQuery, useManualQuery } from 'graphql-hooks';

export interface ImportableItemsPerClassification {
  classificationId: string;
  classificationName: string;
  classificationPath: string;
  items: number;
}

const GET_IMPORTABLE_ITEMS_BY_AMAZON_CLASSIFICATIONS = `
  query GetImportableItemsByAmazonClassifications($selectionId: String!) {
    getImportableItemsByAmazonClassificationsSelection(selectionId: $selectionId) {
      itemsPerClassification {
        classificationId
        classificationName
        classificationPath
        items
      }
    }
  }
`;

interface GetImportableItemsByAmazonClassificationsResponse {
  getImportableItemsByAmazonClassificationsSelection: {
    itemsPerClassification: ImportableItemsPerClassification[];
  };
}

export const useGetImportableItemsByAmazonClassifications = (selectionId?: string) => {
  const { data, loading, error } = useQuery<GetImportableItemsByAmazonClassificationsResponse>(
    GET_IMPORTABLE_ITEMS_BY_AMAZON_CLASSIFICATIONS,
    {
      variables: { selectionId },
      skipRequest: !selectionId
    }
  );

  return {
    itemsPerClassification: data?.getImportableItemsByAmazonClassificationsSelection?.itemsPerClassification ?? [],
    loading,
    error
  };
};

export const useGetImportableItemsByAmazonClassificationsManual = () => {
  const [getImportableItems, { loading, error, data }] = useManualQuery(
    GET_IMPORTABLE_ITEMS_BY_AMAZON_CLASSIFICATIONS
  );

  return {
    getImportableItems,
    loading,
    error,
    data
  };
}; 