import { useEditMlstoreManual } from 'hooks/useEditMlstore';
import { useSearchMlstoreSettingsManual } from 'hooks/useSearchMlstoreSettings';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import ShippingMargins from './accordionComponents/ShippingMargins';
import CostTax from './accordionComponents/CostTax';
import UploadedTemplates from './accordionComponents/UploadedTemplates';
import BlackList from './accordionComponents/BlackList';
import { AccordionItem } from './AccordionItem';
import { CategorySelector } from './accordionComponents/CategorySelector';

export default function SettingPage() {
  const [isValidated, setIsValidated] = useState();
  const [isValidatedKg, setIsValidatedKg] = useState();
  const [editSetting, setEditSetting] = useState(false);
  const [fee, setFee] = useState(0);
  const [meliShipping, setMeliShipping] = useState(0);
  const [waitingDays, setWaitingDays] = useState('');
  const [blackList, setBlackList] = useState([]);
  const [amazonPriceTax, setAmazonPriceTax] = useState({
    option: 0,
    percentage: 0,
  });
  const [amazonAsinTax, setAmazonAsinTax] = useState({
    option: 0,
    percentage: 0,
  });
  const [points, setPoints] = useState({
    detiny: '',
    urls: '',
  });
  const [template, setTemplate] = useState({
    header: '',
    footer: '',
  });
  const [meliCategoryTax, setMeliCategoryTax] = useState([]);

  const { editMlstore, loading } = useEditMlstoreManual();
  const { searchMlstoreSettings } = useSearchMlstoreSettingsManual();

  const [ranges, setRanges] = useState([
    { min: 0, max: 500, percentage: 0 },
    { min: 500, max: 'Infinity', percentage: 0 },
  ]);

  const [rangesKg, setRangesKg] = useState([
    { min: 0, max: 500, kilograms: 0, coin: '0' },
    { min: 500, max: 'Infinity', kilograms: 0, coin: '0' },
  ]);

  useEffect(() => {
    const getSettings = async () => {
      const data = await searchMlstoreSettings();

      if (
        data.data.searchMlstoreSettings.settings !== null &&
        !_.isEmpty(data.data.searchMlstoreSettings.settings)
      ) {
        setEditSetting(true);
        const {
          fee,
          meli_shipping,
          waiting_days,
          profit_percentage,
          shipping_cost,
          amazon_price_tax,
          amazon_asin_tax,
          meli_category_tax,
          black_list,
          template,
          points,
        } = data.data.searchMlstoreSettings.settings;
        setFee(fee);
        setMeliShipping(meli_shipping);
        setWaitingDays(waiting_days);
        setBlackList(black_list);
        setAmazonPriceTax(amazon_price_tax);
        setAmazonAsinTax(amazon_asin_tax);
        setTemplate(template);
        setPoints(points);
        setRanges(profit_percentage);
        setRangesKg(shipping_cost);
        setMeliCategoryTax(meli_category_tax);
      }
    };

    getSettings();
  }, []);

  const onSaveSettings = async () => {
    const settings = {
      profit_percentage: ranges,
      shipping_cost: rangesKg,
      fee: +fee,
      meli_shipping: meliShipping,
      waiting_days: waitingDays,
      amazon_price_tax: amazonPriceTax,
      amazon_asin_tax: amazonAsinTax,
      black_list: blackList,
      meli_category_tax: meliCategoryTax,
      template,
      points,
    };

    const { data } = await editMlstore({
      variables: {
        settings,
      },
    });
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  }

  const data = [
    {
      title: 'Margenes y envios',
      component: (
        <ShippingMargins
          ranges={ranges}
          rangesKg={rangesKg}
          setRanges={setRanges}
          setRangesKg={setRangesKg}
          onSaveSettings={onSaveSettings}
          onValidatedChanged={(value) => setIsValidated(value)}
          isValidated={isValidated}
          onValidatedChangedKg={(value) => setIsValidatedKg(value)}
          isValidatedKg={isValidatedKg}
          loading={loading}
        />
      ),
    },
    {
      title: 'Ajuste de costos, impuestos',
      component: (
        <CostTax
          editSetting={editSetting}
          setFee={setFee}
          fee={fee}
          meliShipping={meliShipping}
          setMeliShipping={setMeliShipping}
          waitingDays={waitingDays}
          setWaitingDays={setWaitingDays}
          amazonPriceTax={amazonPriceTax}
          setAmazonPriceTax={setAmazonPriceTax}
          amazonAsinTax={amazonAsinTax}
          setAmazonAsinTax={setAmazonAsinTax}
          onSaveSettings={onSaveSettings}
          meliCategoryTax={meliCategoryTax}
          setMeliCategoryTax={setMeliCategoryTax}
          isValidated={isValidated}
          isValidatedKg={isValidatedKg}
        />
      ),
    },
    {
      title: 'Lista negra',
      component: (
        <BlackList
          blackList={blackList}
          setBlackList={setBlackList}
          onSaveSettings={onSaveSettings}
          isValidated={isValidated}
          isValidatedKg={isValidatedKg}
        />
      ),
    },
    {
      title: 'Plantilla y subidas de productos',
      component: (
        <UploadedTemplates
          template={template}
          setTemplate={setTemplate}
          points={points}
          setPoints={setPoints}
          onSaveSettings={onSaveSettings}
          isValidated={isValidated}
          isValidatedKg={isValidatedKg}
        />
      ),
    },
    {
      title: 'Categorías de Amazon',
      component: (
        <CategorySelector
          onSaveSettings={onSaveSettings}
          isValidated={isValidated}
          isValidatedKg={isValidatedKg}
        />
      ),
    },
  ];

  return (
    <div
      className='w-full pt-8' style={{ height: 'calc(100vh - 16px)' }} >
      {data.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          component={item.component}
          isOpen={activeIndex === index}
          onClick={() => handleItemClick(index)}
          index={index}
          data={data}
        />
      ))}
    </div>
  );
}
