import { useEffect, useState } from "react";
import SaveButtonComponent from "../utilsComponents/SaveButtonComponent";

const UploadedTemplates = ({
  template,
  setTemplate,
  points,
  setPoints,
  onSaveSettings,
  isValidated,
  isValidatedKg,
}) => {
  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='w-full flex max-h-[580px] h-[85%]'>
        <div className='w-full border-2 border-r-primary-100 border-x-white border-y-white'>
          <div className='mb-2'>
            <span>
              <h2>Descripcion al final de cada aplicacion comun</h2>
            </span>
          </div>
          <div className='mb-2'>
            <span>
              <h2>Cabecera</h2>
            </span>
          </div>
          <div className='mb-4 flex w-full'>
            <textarea
              className='border-gray-300 block w-full rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-4'
              rows={2}
              cols={55}
              id='header'
              name='header'
              value={template.header}
              onChange={(e) =>
                setTemplate({ ...template, header: e.target.value })
              }
            />
          </div>
          <div className='mb-2'>
            <span>
              <h2>Pie de pagina</h2>
            </span>
          </div>
          <div className='mb-4 flex w-full'>
            <textarea
              className='border-gray-300 block w-full rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-4'
              rows={6}
              cols={55}
              id='footer'
              name='footer'
              value={template.footer}
              onChange={(e) =>
                setTemplate({ ...template, footer: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className='w-full flex mt-2 justify-end'>
        <SaveButtonComponent
          onSaveSettings={onSaveSettings}
          isValidated={isValidated}
          isValidatedKg={isValidatedKg}
        />
      </div>
    </div>
  );
};

export default UploadedTemplates;
