import React, { useRef, useState } from 'react';
import axios, { CancelToken, isCancel } from 'axios';
import Document2Icon from 'components/utils/iconComponents/Document2Icon';
import XMarkIcon from 'components/utils/iconComponents/XMarkIcon';

const OrderAttatchDocument = ({
  file,
  fileSize,
  setFile,
  base64File,
  orderInfo,
  sendMessageToBuyer,
  messagesBuyer,
  setMessagesBuyer,
}) => {
  const cancelFileUpload = useRef(null);
  const [status, setStatus] = useState('idle');
  const [uploadProgress, setUploadProgress] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const clearFields = () => {
    setFile(null);
    setStatus('idle');
    setUploadProgress(0);
  };

  async function handleFileUpload() {
    if (!file) return;

    setStatus('uploading');
    setUploadProgress(0);

    // const formData = new FormData();
    // formData.append('file', file);

    const formData = { file: base64File, fileName: file.name };

    try {
      const responseFileUpload = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/upload-file/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
                process.env.TOKEN || JSON.parse(localStorage?.session)?.sessionInfo?.token
               }`,
          },
          onUploadProgress: (progressEvent) => {
            const progress = progressEvent.total
              ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
              : 0;
            setUploadProgress(progress);
          },
          cancelToken: new CancelToken(
            (cancel) => (cancelFileUpload.current = cancel)
          ),
        }
      );

    //   console.info('responseFileUpload..', responseFileUpload);

        if (responseFileUpload?.data?.id != '') {
          const pack_id_message = orderInfo.pack_id
            ? orderInfo.pack_id
            : orderInfo.order_id;

          const dataSent = await sendMessageToBuyer({
            variables: {
              orderId: pack_id_message,
              buyerId: orderInfo.buyer.id.toString(),
              dataInput: '...',
              attachments: responseFileUpload.data.id,
            },
          });

        //   console.info('dataSent...', dataSent);

          if (dataSent) {
            setMessagesBuyer([
              ...messagesBuyer,
              dataSent.data.sendMessageToBuyer.messages,
            ]);
          }
        }

      setStatus('success');
      setUploadProgress(100);
    } catch (err) {
      setStatus('error');
      setUploadProgress(0);
      setErrorMessage(err.message);
      if (isCancel(err)) {
        setErrorMessage(err.message);
      }
    }
  }

  const cancelUploadHandler = () => {
    cancelFileUpload.current && cancelFileUpload.current('Envio cancelado!');
  };

  return (
    <div
      className={`flex justify-end fixed right-4 bottom-52 ${
        file ? 'visible' : 'hidden'
      }`}
    >
      <div className='w-[400px] h-[120px] rounded-xl shadow border border-gray-100 bg-white'>
        <div className='flex h-full pt-4 pr-1 pb-1 pl-1 '>
          <div className='flex-none w-14 h-full pl-4'>
            <span className='flex h-[32px] text-[#98A2B3]'>
              <Document2Icon />
            </span>
          </div>
          <div className='w-full'>
            <div className='flex w-full h-[40%]'>
              <div className='grow h-full'>
                <div className='grid grid-rows-2'>
                  <span className='text-sm font-medium text-[#344054] truncate'>
                    {file && file.name}
                  </span>
                  <span className='text-sm font-normal text-[#475467]'>
                    {file && fileSize}
                  </span>
                </div>
              </div>
              <div className='flex-none w-14 h-full pl-4'>
                <span
                  onClick={clearFields}
                  className='flex h-[25px] cursor-pointer'
                >
                  <XMarkIcon />
                </span>
              </div>
            </div>
            <div className='h-[53%] content-center'>
              <div className='h-[30%]'>
                {status === 'uploading' && (
                  <div className={`flex justify-between items-center mr-[5px]`}>
                    <div className='h-2.5 w-[87%] rounded-full bg-primary-200'>
                      <div
                        className='h-2.5 rounded-full bg-primary-700 transition-all duration-300'
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>
                    <p className='text-sm text-[#475467]'>{uploadProgress}%</p>
                  </div>
                )}
              </div>
              <div className='w-full h-[70%] flex justify-center items-end'>
                {file && status !== 'success' && status !== 'error' && (
                  <div
                    className={`w-[32%] h-[70%] border border-gray-300 font-bold rounded-md px-4 pt-0.5 text-sm justify-center flex cursor-pointer`}
                    onClick={
                      file && status === 'uploading'
                        ? cancelUploadHandler
                        : handleFileUpload
                    }
                  >
                    {status === 'uploading' ? 'Cancelar' : 'Enviar'}
                  </div>
                )}
                {status === 'success' && (
                  <p className='text-sm text-green-600'>
                    Archivo cargado con exito!
                  </p>
                )}
                {status === 'error' && (
                  <p className='text-sm text-red-500'>{errorMessage}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderAttatchDocument;
