import React, { useEffect, useState } from 'react';
import { useGetAmazonTreeNodeManual } from 'hooks/useGetAmazonTreeNode';
import { useTree } from 'contexts/TreeSelectionContext';
import _ from 'lodash';
import { ImportableItemsPerClassification } from 'hooks/useGetImportableItemsByAmazonClassifications';

export interface TreeNode {
    id: string;
    name: string;
    childrenIds: string[];
    children: TreeNode[];
}

interface TreeSelectorProps {
    nodeId: string;
    expandedNodes?: string[];
    onNodeToggle?: (nodeId: string, isExpanded: boolean) => void;
    itemsPerClassification?: ImportableItemsPerClassification[];
    onUnselectedItem?: () => void;
    preSelected?: boolean;
    parentNode?: TreeNode;
}

export const TreeSelector: React.FC<TreeSelectorProps> = ({
    nodeId,
    expandedNodes = [],
    onNodeToggle,
    onUnselectedItem,
    preSelected,
    parentNode
}) => {
    const { getAmazonTreeNode, data, loading } = useGetAmazonTreeNodeManual();
    const { state, dispatch } = useTree();
    const [showChildren, setShowChildren] = useState(nodeId === 'root');
    const [node, setNode] = useState<TreeNode | null>(null);
    const [isChecked, setIsChecked] = useState(state.selectedNodes.has(nodeId));

    useEffect(() => {
        getAmazonTreeNode({
            variables: {
                id: nodeId,
                levels: 9
            }
        });
    }, [nodeId]);

    useEffect(() => {
        setNode(data?.getAmazonTreeNode);

        if (data?.getAmazonTreeNode && nodeId === 'null') {
            dispatch({ type: 'SET_ROOT_NODE', node: data.getAmazonTreeNode });
        }
    }, [data]);

    useEffect(() => {
        setIsChecked(node ? state.selectedNodes.has(node.id) : false);
    }, [node, state.selectedNodes]);

    const handleCheckboxChange = (checked: boolean, fromChild: boolean = false) => {
        if (!node) return;

        if (checked) {
            const childrenIds = getAllChildrenIds(node);
            dispatch({ type: 'SELECT_MULTIPLE', nodeIds: [node.id, ...childrenIds] });
        } else {
            if (fromChild) {
                dispatch({ type: 'UNSELECT_SINGLE_WITH_CHILDREN', nodeId: node.id });
            } else {
                const childrenIds = getAllChildrenIds(node);
                dispatch({ type: 'UNSELECT_MULTIPLE', nodeIds: [node.id, ...childrenIds] });
            }
            onUnselectedItem?.();
        }
    };

    const getAllChildrenIds = (node: TreeNode): string[] => _.flatMap(node.children, child => [child.id, ...getAllChildrenIds(child)]);

    const handleToggle = (nodeId: string) => {
        if (onNodeToggle) {
            onNodeToggle(nodeId, !expandedNodes.includes(nodeId));
        }
    };

    if (loading) return <div className="pl-8 select-none">Cargando...</div>;
    if (!node) return null;
    
    const sortedChildren = _.sortBy(node.children, 'name');

    if (node.id === 'null') {
        return (
            <div className="flex flex-col h-full select-none">
                <div className="flex-1 overflow-y-auto">
                    {sortedChildren.map(childNode => (
                        <TreeSelector
                            key={childNode.id}
                            nodeId={childNode.id}
                            onUnselectedItem={() => handleCheckboxChange(false, true)}
                            preSelected={isChecked}
                            parentNode={node}
                            expandedNodes={expandedNodes}
                            onNodeToggle={handleToggle}
                        />
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div className="pl-4 select-none cursor-pointer" key={node.id}>
            <span
                onClick={() => sortedChildren.length && handleToggle(node.id)}
                className="inline-block w-4 text-center"
            >
                {expandedNodes.includes(node.id) ? '-' : sortedChildren.length ? '+' : ' '}
            </span>
            <input
                type="checkbox"
                className="form-checkbox mr-2 text-primary-700"
                checked={isChecked}
                onChange={(e) => handleCheckboxChange(e.target.checked)}
            />
            <span onClick={() => sortedChildren.length && handleToggle(node.id)}>
                {node.name}
            </span>
            {expandedNodes.includes(node.id) &&
                sortedChildren.length > 0 &&
                sortedChildren.map(childNode => (
                    <TreeSelector
                        key={childNode.id}
                        nodeId={childNode.id}
                        onUnselectedItem={() => handleCheckboxChange(false, true)}
                        preSelected={isChecked}
                        parentNode={node}
                        expandedNodes={expandedNodes}
                        onNodeToggle={handleToggle}
                    />
                ))}
        </div>
    );
};
