import { useQuery, useManualQuery } from 'graphql-hooks';

interface QueueAmazonClassificationImportInput {
  storeId: string;
  classificationId: string;
  quantity: number;
}

interface QueueAmazonClassificationImportResponse {
  queueAmazonClassificationImport: {
    success: boolean;
  };
}

export const QUEUE_AMAZON_CLASSIFICATION_IMPORT_MUTATION = `
  mutation QueueAmazonClassificationImport($quantities: [QueueAmazonClassificationImportInput!]!) {
    queueAmazonClassificationImport(quantities: $quantities) {
      success
    }
  }
`;

export const useQueueAmazonClassificationImport = () => {
    const { data, loading, error } = useQuery<QueueAmazonClassificationImportResponse>(
    QUEUE_AMAZON_CLASSIFICATION_IMPORT_MUTATION, {
        variables: {
            quantities: []
        }
    }
  );

  const queueImportManual = useManualQuery

};


export const useQueueAmazonClassificationImportManual = () => {
    const [queueImportManual, { loading, error }] = useManualQuery<QueueAmazonClassificationImportResponse>(
        QUEUE_AMAZON_CLASSIFICATION_IMPORT_MUTATION
    )

    return {
        queueImportManual,  
        loading,
        error
    }
}   