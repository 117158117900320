import React, { useEffect } from 'react';
import { useGetImportQueue } from 'hooks/useGetImportQueue';


interface ImportQueueProps {
  onNewImport?: () => void;
}

export const ImportQueue: React.FC<ImportQueueProps> = ({ onNewImport }) => {
  const { getImportQueue, data, loading } = useGetImportQueue();
  const tasks = data?.getImportQueue?.tasks || [];

  useEffect(() => {
    getImportQueue();
  }, []);

  useEffect(() => {
    if (tasks.length === 0) {
      setTimeout(() => {
        getImportQueue();
      }, 5000);
    }
  }, [data]);

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="px-6 py-4 flex justify-between items-center border-b border-gray-200">
        <h2 className="text-lg font-semibold text-gray-900">Cola de Importación</h2>
        <button
          onClick={onNewImport}
          className="inline-flex items-center px-4 py-2 rounded-lg bg-primary-600 text-white hover:bg-primary-700 transition-colors"
        >
          Nueva Importación
        </button>
      </div>

      <div className="p-6">
        {loading ? (
          <div className="flex justify-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
          </div>
        ) : tasks.length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            No hay importaciones en cola
          </div>
        ) : (
          <div className="space-y-4">
            {tasks.map((task: any) => (
              <div
                key={task.id}
                className="p-4 rounded-lg border border-gray-200 hover:border-primary-200 transition-colors"
              >
                <div className="flex justify-between items-start">
                  <div>
                    <div className="flex items-center gap-2">
                      <span className="text-gray-700">Cantidad: {task.quantity}</span>
                      <StatusBadge status={task.status} />
                    </div>
                    <div className="text-sm text-gray-500 mt-1">
                      Categoría: {task.classification_name}
                    </div>
                    <div className="text-sm text-gray-500 mt-1">
                      Creado: {new Date(parseInt(task.createdAt)).toLocaleString()}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const StatusBadge: React.FC<{ status: string }> = ({ status }) => {
  const statusStyles = {
    created: 'bg-primary-50 text-primary-700 border-primary-200',
    processing: 'bg-yellow-50 text-yellow-700 border-yellow-100',
    completed: 'bg-green-50 text-green-700 border-green-100',
    failed: 'bg-red-200 text-red-700 border-red-200',
  };

  const statusText = {
    created: 'En cola',
    processing: 'Procesando',
    completed: 'Completado',
    failed: 'Error',
  };

  return (
    <span
      className={`px-2 py-1 text-xs font-medium rounded-full border ${
        statusStyles[status as keyof typeof statusStyles]
      }`}
    >
      {statusText[status as keyof typeof statusText]}
    </span>
  );
};

